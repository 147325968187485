<template>
  <div class="page-container">
    <Table ref="tableRef" :params="params" :request="searchData">
      <el-table-column prop="robotSN" label="机器人编码" />
      <el-table-column prop="cabinetSN" label="控制器编码" />
      <el-table-column prop="errorCode" label="报警编码" />
      <el-table-column prop="errorDescription" label="报警详情" />
      <el-table-column prop="errorDate" label="发生时间" />
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{ row }">
          <el-button v-if="row.downloadRequestStatus == 0" @click="pullRobotLog(row)" type="text" size="small"
            >拉取机器人日志</el-button
          >
          <el-button v-else-if="row.downloadPath" @click="downLoadFile(row.downloadPath)" type="text" size="small"
            >故障日志下载</el-button
          >
          <el-button v-else type="text" size="small"
            >等待机器人响应</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { common } from "@/common/mixin";
import { reactive, toRefs } from "vue";
// import { watch } from 'vue'
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/elp/table";
import { reqErrorLogList, reqErrorLogPull, reqUpgradeDownload } from '@/api/robot'
import dayjs from 'dayjs'

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      tableRef: null,
      detailData: {},
      params: [{
        key: 'robotSN',
        value: '',
        label: '机器人编码'
      },{
        key: 'cabinetSN',
        value: '',
        label: '控制柜编码'
      },{
        type: "Date",
        key: "dateRange",
        value: [dayjs().subtract(7, 'day').format('YYYY-MM-DD') +' 00:00:00', dayjs().format('YYYY-MM-DD') +' 23:59:59'],
        label: "发生时间",
        options: {
          "type": "datetimerange",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
        }
      }],
    })

    const searchData = async (params) => {
      const query = { ...params }
      if (params.dateRange?.length) {
        query.startTime = params.dateRange[0]
        query.endTime = params.dateRange[1]
      } else {
        query.startTime = undefined
        query.endTime = undefined
      }
      return reqErrorLogList(query) 
    }
    const downLoadFile = async (url) => {
      // let a = document.createElement('a')
      // a.download = 'errorLog'
      // a.href = url
      // a.click()
      try {
        const res = await reqUpgradeDownload({
          fileUrl: url
        })
        // console.log('downLoadFile', res)
        // let reader = new FileReader();
        // reader.readAsDataURL(res.data);
        // reader.onload = (e) => {
        //   const a = document.createElement('a')
        //   a.download = '故障日志'
        //   a.href = e.target.result
        //   a.click()
        // }
      } catch (error) {
        console.error(error)
        ElMessage({
          showClose: true,
          type: "error",
          message: error.message || "下载失败!"
        })
      }
    }
    const pullRobotLog = async (item) => {
      try {
        await reqErrorLogPull({
          id: item.id
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "日志拉取中，请稍后查看"
        })
        setTimeout(() => {
          state.tableRef?.update()
        }, 5000)
      } catch (error) {
        console.error(error)
      }
    }
    return {
      ...toRefs(state),
      searchData,
      downLoadFile,
      pullRobotLog
    }
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
