<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="robotId" label="机器人编码" />
      <!-- <el-table-column prop="productName" label="产品名称" /> -->
      <el-table-column prop="modelName" label="规格型号" />
      <el-table-column prop="cabinetId" label="控制器编码" />
      <!-- <el-table-column prop="version" label="软件版本" />
      <el-table-column prop="companyName" label="公司名称" width="150" /> -->
      <el-table-column prop="startTime" label="发生时间" width="190" />
      <el-table-column prop="queryTime" label="查询时间" width="180" />
      <el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button type="primary" plain size="mini" @click="handleClick(scope.row)"
            >刷新状态</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import dayjs from 'dayjs'

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data() {
    return {
      params: [{
        key: 'robotId',
        value: '',
        label: '机器人编码'
      },{
        key: 'cabinetId',
        value: '',
        label: '控制柜编码'
      },{
        type: "Date",
        key: "timeRange",
        value: [dayjs().subtract(7, 'day').format('YYYY-MM-DD') +' 00:00:00', dayjs().format('YYYY-MM-DD') +' 23:59:59'],
        label: "发生时间",
        options: {
          "type": "datetimerange",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
        }
      }],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [
        // {
        //   robotId: "Zu7210101",
        //   productName: "JAKA Zu",
        //   modelName: "Zu 7",
        //   cabinetId: "123456789",
        //   version: "15.0.0",
        //   companyName: "汽车零部件公司",
        //   startTime: "2021-05-27 14:55:00.123",
        //   queryTime: "2021-05-24 17:48:20",
        // },
      ],
    };
  },
  created() {
  },
  mounted() {
    this.$refs.filterRef?.searchData()
  },
  methods: {
    getData() {
      request("getEventEmergency", {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then((data) => {
          this.tableDataList = data.records;
          this.total = data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDataMap() {
      try {
        request("getArea", {
          parentId: 1,
        }).then((data) => {
          this.provinceList = data;
        });
        request("getRobotStatusMap").then((data) => {
          this.robotStatusList = data;
        });
        request("getIndustryList").then((data) => {
          this.industryList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleClick() {
      this.getData();
    },
    searchData(data) {
      this.query = data
      this.getData()
    },
    updatePage(page, size) {
        this.page = page || 1
        this.size = size || 10
        this.getData()
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
</style>