<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <!-- <el-table-column prop="ledgerBatch" label="	单据批次"/> -->
      <el-table-column prop="createTime" label="创建时间" width="160"/>
      <el-table-column prop="ledgerBatch" label="录入时间" width="160"/>
      <el-table-column prop="action" label="动作" width="100"/>
      <el-table-column prop="robotSerialNumber" label="机器人编码"/>
      <el-table-column prop="cabinetSerialNumber" label="控制柜编码"/>
      <el-table-column prop="cabinetProgram" label="控制柜程序"/>
      <el-table-column prop="robotServoProgram" label="伺服程序"/>
      <el-table-column prop="appVersion" label="APP版本"/>
      <el-table-column prop="customer" label="客户" width="120"/>
      <el-table-column prop="notes" label="备注"/>
      <!-- <el-table-column prop="operator" label="操作人"/> -->
      <el-table-column label="操作" fixed="right" width="100">
        <template #default="scope">
          <el-button type="primary" plain size="mini" @click="editRobot(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="formRef" class="dialog-form" :model="form" :rules="formRules" label-width="150px">
        <el-form-item label="机器人生产批次" prop="robotProductionBach">
          <el-input v-model="form.robotProductionBach" placeholder="机器人生产批次"></el-input>
        </el-form-item>
        <el-form-item label="机器人编码" prop="robotSerialNumber">
          <el-input v-model="form.robotSerialNumber" placeholder="机器人编码"/>
        </el-form-item>
        <el-form-item label="机器人伺服程序" prop="robotServoProgram">
          <el-input v-model="form.robotServoProgram" placeholder="机器人伺服程序"/>
        </el-form-item>
        <el-form-item label="控制柜生产批次" prop="cabinetProductionBatch">
          <el-input v-model="form.cabinetProductionBatch" placeholder="控制柜生产批次"/>
        </el-form-item>
        <el-form-item label="控制柜编码" prop="cabinetSerialNumber">
          <el-input v-model="form.cabinetSerialNumber" placeholder="控制柜编码"/>
        </el-form-item>
        <el-form-item label="控制柜程序" prop="cabinetProgram">
          <el-input v-model="form.cabinetProgram" placeholder="控制柜程序"/>
        </el-form-item>
        <el-form-item label="APP版本" prop="appVersion">
          <el-input v-model="form.appVersion" placeholder="APP版本"/>
        </el-form-item>
        <el-form-item label="客户" prop="customer">
          <el-input v-model="form.customer" placeholder="客户"/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.notes" placeholder="备注"/>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input v-model="form.operator" placeholder="操作人"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" size="small" @click="onSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/utils/request';
import { common } from '@/common/mixin';
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { ElMessage } from "element-plus";

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data() {
    return {
      dialogFormVisible: false,
      formRef: null,
      form: {
        robotProductionBach: '',
        robotSerialNumber: '',
        robotServoProgram: '',
        cabinetProductionBatch: '',
        cabinetSerialNumber: '',
        cabinetProgram: '',
        province: '',
        appVersion: '',
        customer: '',
        notes: '',
        operator: '',
        saleTime: ''
      },
      formRules: {
        robotProductionBach: {
          required: true,
          message: '请输入机器人生产批次',
          trigger: 'blur',
        },
        robotSerialNumber: {
          required: true,
          message: '请输入机器人编码',
          trigger: 'blur',
        },
        robotServoProgram: {
          required: true,
          message: '请输入机器人伺服程序',
          trigger: 'blur'
        },
        cabinetProductionBatch: {
          required: true,
          message: '请输入控制柜生产批次',
          trigger: 'blur',
        },
        cabinetSerialNumber: {
          required: true,
          message: '请输入控制柜编码',
          trigger: 'blur',
        },
        cabinetProgram: {
          required: true,
          message: '请输入控制柜程序',
          trigger: 'blur',
        },
        appVersion: {
          required: true,
          message: '请输入APP版本',
          trigger: 'blur',
        },
        customer: {
          required: true,
          message: '请输入客户',
          trigger: 'blur',
        }
      },
      params: [{
        key: 'robotSerialNumber',
        value: '',
        label: '机器人编码'
      },{
        key: 'customer',
        value: '',
        label: '客户'
      },{
        key: 'cabinetSerialNumber',
        value: '',
        label: '控制柜编码'
      },{
        key: 'cabinetProgram',
        value: '',
        label: '控制柜程序'
      }],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      robotStatusList: [], // 机器人状态下拉列表
      industryList: [], // 行业下拉列表
    }
  },
  watch: {
    "query.province": function(value, old) {
      if (value && (value!==old)) {
        console.log('province', value)
        request('getArea', {
          parentId: Number(value)
        })
        .then((data) => {
          this.cityList = data;
        })
      }
    },
    "query.city": function(value, old) {
      if (value && (value!==old)) {
        request('getArea', {
          parentId: Number(value)
        })
        .then((data) => {
          this.areaList = data;
        })
      }
    }
  },
  created() {
    window.$this = this;
    this.getData()
    // this.getDataMap()
  },
  mounted () {
    // this.tableDataList = robotList({}).then(data => {
    //   this.tableDataList = data
    // })
    // this.$store.dispatch('status/robot', {})
    // .then(data => {
    //   console.log(data)
    // })
    // robotList({}).then(data => {
    //   this.tableDataList = data
    // })
  },
  methods: {
    getData() {
      request('getLedgerList', {
        ...this.query,
        page: this.page,
        size: this.size
      })
      .then((data) => {
        this.tableDataList = data.records;
        this.total = data.total;
        // this.tableDataList = [].concat(data.records);
      })
      .catch((error) => {
        console.log(error);
      })
    },
    getDataMap() {
      try {
        request('getArea', {
          parentId: 1
        })
        .then((data) => {
          this.provinceList = data;
        })
        request('getRobotStatusMap')
        .then((data) => {
          this.robotStatusList = data;
        })
        request('getIndustryList')
        .then((data) => {
          this.industryList = data;
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchData(data) {
      this.query = data
      this.getData()
    },
    updatePage(page, size) {
        this.page = page || 1
        this.size = size || 10
        this.getData()
    },
    addNewRobot() {
      for (let key of Object.keys(this.form)) {
        this.form[key] = ''
      }
      this.dialogFormVisible = true
    },
    editRobot(data) {
      console.log(data)
      for (let key of Object.keys(this.form)) {
        if (data[key]) {
          this.form[key] = data[key]
        } else {
          this.form[key] = ''
        }
      }
      this.form.id = data.id
      this.dialogFormVisible = true
      return
    },
    async onSubmit() {
      try {
        this.$refs.formRef && await this.$refs.formRef.validate()
        const api =  this.form.id ? 'updateLedger' : 'addLedger'
        await request(api, this.form)
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        this.dialogFormVisible = false
        this.getData()
      } catch (error) {
        console.error(error)
      }    
    },
    onCancel () {
      this.dialogFormVisible = false
      
    },
    dateChange() {

      console.log('dateChange: ', this.dateRange)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
