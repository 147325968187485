<template>
  <div class="page-container">
    <!-- <List :data="jakaData">
      <template #header>节卡全球</template>
      <template #default="{item}">
        <ListItem :data="item">
          <template #suffix>
            <el-button type="primary" plain size="mini">编辑</el-button>
            <el-button type="danger" plain size="mini">删除</el-button>
          </template>
        </ListItem>
      </template>
      <template #footer>用机器人解放双手</template>
    </List> -->
  </div>
</template>

<script>
import { ref } from "vue";
// import List from "../components/list/list.js"
// import ListItem from "../components/list/listItem.js"

export default {
  components: {
    // List,
    // ListItem
  },
  setup() {
    const jakaData = ref([
      { title: "上海", content: 100, description: '上海研发基地' },
      { title: "常州", content: 100, description: '常州生产基地' },
      { title: "深圳", content: 100, description: '深圳分公司' },
      { title: "天津", content: 100, description: '天津技术中心' },
      { title: "东京", content: 100, description: '日本技术中心' }
    ])
    return {
      jakaData
    }
  },
}
</script>
<style lang="scss" scoped>
.page-container {
  position: relative;
  height: 100%;
  margin: 50px 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  z-index: 10;
  // display: flex;
  // flex-direction: column;
}
</style>