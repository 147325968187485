<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <div class="chart-container">
      <div class="canvas" id="lineChart"></div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, onUnmounted } from "vue";
// import { useRouter } from 'vue-router'
import { ElMessage } from "element-plus";
import Filter from "@/components/table/filter";
import { request, GET } from "@/utils/request";
import { common } from "@/common/mixin";
import { getUrlParam } from '@/common/utils';
// import * as echarts from "echarts";
import * as echarts from 'echarts/core';
import {
    DatasetComponent,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import {
    LineChart
} from 'echarts/charts';

export default {
  components: {
    Filter,
  },
  mixins: [common],
  setup() {
    echarts.use(
      [DatasetComponent, TitleComponent, TooltipComponent, DataZoomComponent, LegendComponent, ToolboxComponent, GridComponent, CanvasRenderer, LineChart,]
    )
    const robotId = getUrlParam(window.location.href, 'robotId')
    const jointId = getUrlParam(window.location.href, 'jointId')
    console.log('robotId', robotId)
    let lineChart = null // echarts 实例
    const jointFields = ref([])
    let jointData = []
    const state = reactive({
      query: {
        robotId: robotId, // Zu3100161
        field: 1,
        startTime: '',
        endTime: ''
      },
      params: [
        {
          type: "Input",
          key: "robotId",
          value: robotId,
          label: "机器人ID"
        },
        {
          type: "Select",
          key: "field",
          value: 1,
          label: "参数",
          options: {
            data: jointFields,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始时间",
            "end-placeholder": "结束时间",
            'default-time': [
              new Date(2000, 1, 1, 0, 0, 0),
              new Date(2000, 1, 1, 23, 59, 59)
            ]
            // "disabled-date": disabledDate

          }
        }
      ]
    })
    const getJointFields = async () => {
      try {
        const res = await GET('getJointFields')
        jointFields.value = res
      } catch (error) {
        console.error(error)
      }
    }
    const getJointData = async () => {
      try {
        const res = await request('getJointData', {
          ...state.query,
        })
        let title = ''
        for (let item of jointFields.value) {
          if (item.value == state.query.field) {
            title = item.label
            break
          }
        }
        jointData = res || []
        if (jointData.length <= 0) return
        lineChart.resize()
        lineChart.setOption({
          title: {
            text: title,
          },
          xAxis: {
            min: jointData[0].time||'',
          },
          dataset: {
            source: jointData
          }
        })
      } catch (error) {
        console.error(error);
      }
    };
    const exportJointData = async () => {
      try {
        const res = await request('exportJointData', {
          ...state.query,
        })
        let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = ' 二维码.zip'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      state.query = data
      if (!state.query.robotId) {
        ElMessage({
          showClose: true,
          type: "warning",
          message: "机器人ID不能为空"
        })
        return
      }
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startTime = data.dateRange[0]
        state.query.endTime = data.dateRange[1]
      } else {
        state.query.startTime = ''
        state.query.endTime = ''
      }
      getJointData()
    }
    getJointFields()
    onMounted(() => {
      lineChart = echarts.init(document.getElementById("lineChart"))
      let selected = {}
      jointId && ["关节1", "关节2", "关节3", "关节4", "关节5", "关节6"].forEach((item) => {
        if ('关节'+jointId != item) selected[item] = false
      })
      const lineOption = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["关节1", "关节2", "关节3", "关节4", "关节5", "关节6"],
          selected: selected
        },
        grid: {
          left: "2%",
          right: "0%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            // myDownload: {
            //     show: true,
            //     title: '导出',
            //     icon: 'image://data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgY2xhc3M9Imljb24iIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtdi0wNDJjYTc3ND0iIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik01NDQgODA1Ljg4OFYxNjhhMzIgMzIgMCAxMC02NCAwdjYzNy44ODhMMjQ2LjY1NiA1NTcuOTUyYTMwLjcyIDMwLjcyIDAgMDAtNDUuMzEyIDAgMzUuNTIgMzUuNTIgMCAwMDAgNDguMDY0bDI4OCAzMDYuMDQ4YTMwLjcyIDMwLjcyIDAgMDA0NS4zMTIgMGwyODgtMzA2LjA0OGEzNS41MiAzNS41MiAwIDAwMC00OCAzMC43MiAzMC43MiAwIDAwLTQ1LjMxMiAwTDU0NCA4MDUuODI0eiI+PC9wYXRoPjwvc3ZnPg==',
            //     onclick: function () {
            //       exportJointData()
            //     }
            // }
          },
        },
        dataset: {
          dimensions: ['time', 'joint1', 'joint2', 'joint3', 'joint4', 'joint5', 'joint6'],
          source: jointData
        },
        xAxis: {
          type: "time",
          // min: "2022-01-01 00:00:00",
          splitLine: {
            show: false
          },
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          // min: 'dataMin',
          // max: 'dataMax',
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        dataZoom: [
          // {
          //   type: 'inside',
          //   start: 0,
          //   end: 100
          // },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          { name: '关节1', type: "line", encode: { x: 'time', y: 'joint1' }, showSymbol: false, sampling: 'average'},
          { name: '关节2', type: "line", encode: { x: 'time', y: 'joint2' }, showSymbol: false, sampling: 'average'},
          { name: '关节3', type: "line", encode: { x: 'time', y: 'joint3' }, showSymbol: false, sampling: 'average'},
          { name: '关节4', type: "line", encode: { x: 'time', y: 'joint4' }, showSymbol: false, sampling: 'average'},
          { name: '关节5', type: "line", encode: { x: 'time', y: 'joint5' }, showSymbol: false, sampling: 'average'},
          { name: '关节6', type: "line", encode: { x: 'time', y: 'joint6' }, showSymbol: false, sampling: 'average'}
        ],
        animation: false,
      }
      lineChart.setOption(lineOption)
      if (state.query.robotId) getJointData()
    })
    onUnmounted(() => {
      lineChart && lineChart.dispose() 
    })
    return {
      ...toRefs(state),
      searchData,
      getJointFields,
      getJointData,
      exportJointData
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  position: relative;
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.chart-container {
  position: relative;
  margin-top: 10px;
  padding: 10px 10px;
  flex: 1;
  // overflow: hidden;
  border-color: #fff;
  border-radius: 5px;
  background-color: #fff;
  .canvas {
    width: 100%;
    height: 100%;
  }
}

</style>