<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage" select @select="handleSelect">
      <template #toolBar>
        <span style="margin-right: 20px;">
          当前OTA最新版本：{{ otaNewVer }}
        </span>
        <el-button :disabled="!batchDataList.length" size="small" type="primary" @click="upgradeOTA">升级选中设备</el-button>
        <!-- <el-button size="small" type="primary" @click="upgradeOTA">升级全部设备</el-button> -->
      </template>
      <el-table-column prop="robotId" label="机器人编码" width="100" />
      <el-table-column prop="company" label="客户名称" width="100">
        <template #default="scope">
          {{ scope.row.company }}
        </template>
      </el-table-column>
      <el-table-column prop="modelName" label="规格型号" width="100" />
      <el-table-column prop="cabinetId" label="控制器编码" width="100" />
      <!-- <el-table-column prop="version" label="软件版本" />
      <el-table-column prop="companyName" label="公司名称" /> -->
      <!-- <el-table-column label="机器人位置">
        <template #default="{row}">
          {{ row.ip }} 
          {{ row.ip && (row.province || row.city) ? ' - ' : '' }} 
          {{ row.province }} {{ row.city }} 
        </template>
      </el-table-column> -->
      <el-table-column prop="location" label="机器人位置" width="120" />
      <el-table-column prop="qccid" label="物联网卡号" width="100" />
      <el-table-column
        prop="status"
        label="机器人状态"
        :formatter="formatStatus"
      />
      <el-table-column label="是否碰撞状态">
        <template #default="scope">
          {{ scope.row.protectiveStop ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="是否急停状态">
        <template #default="scope">
          {{ scope.row.emergencyStop ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="firstReceiveTime" label="首次接入时间" width="180" />
      <el-table-column prop="sumRunningTime" label="累计运行时间" width="120" />
      <el-table-column prop="queryTime" label="查询时间" width="180" />
      <el-table-column prop="allowOtaUpgrade" label="允许自动升级" width="100">
        <template #default="{ row }">{{ row.allowOtaUpgrade ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column prop="otaClientVersion" label="当前OTA版本" width="100" />
      <!-- <el-table-column prop="targetOtaClientVersion" label="目标OTA版本" width="100" />
      <el-table-column prop="otaUpgradeStatus" label="升级状态" width="100">
        <template #default="{ row }">{{ otaUpgradeMap[row.otaUpgradeStatus] }}</template>
      </el-table-column>
      <el-table-column prop="errorMessage" label="失败原因" width="180" show-overflow-tooltip />
      <el-table-column prop="otaUpgradeTime" label="升级操作时间" width="180" /> -->
      <el-table-column label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button type="primary" plain size="mini" @click="handleClick(scope.row)"
            >刷新状态</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
import { ElMessage } from "element-plus";
import { robotStatusMap, statusList } from "@/common/constant";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { otaUpgradeList, otaUpgradeMap } from "@/common/constant"
import { reqUpgradeBatch, reqUpgradeVerList, reqUpgradeNewVer } from "@/api/robot"
import { reactive, ref, toRefs } from "vue";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {
        robotId: "",
        cabinetId: "",
        firstReceiveTime: [],
        status: "",
        robotName: "",
        company: "",
        protectiveStop: "",
        emergencyStop: "",
      },
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      options: [
        {
          id: true,
          name: "是",
        },
        {
          id: false,
          name: "否",
        },
      ],
      otaNewVer: '',
      batchDataList: [],
      otaUpgradeMap
    })
    const otaVerList = ref([])
    const params = reactive([
      {
        key: "robotId",
        value: "",
        label: "机器人编码",
      },
      {
        key: "cabinetId",
        value: "",
        label: "控制柜编码",
      },
      {
        key: "company",
        value: "",
        label: "客户名称",
      },
      {
        type: "Date",
        key: "firstReceiveTime",
        value: [],
        label: "首次接入日期",
        options: {
          "type": "datetimerange",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
        }
      },
      {
        type: "Select",
        key: "status",
        value: "",
        label: "机器人状态",
        options: {
          data: statusList,
          label: "name",
          value: "id",
        },
        attrs: {
          multiple: true
        }
      },
      {
        type: "Select",
        key: "protectiveStop",
        value: "",
        label: "是否碰撞",
        options: {
          data: [
            {
              id: true,
              name: "是",
            },
            {
              id: false,
              name: "否",
            },
          ],
          label: "name",
          value: "id"
        }
      },
      {
        type: "Select",
        key: "emergencyStop",
        value: "",
        label: "是否急停",
        options: {
          data: [
            {
              id: true,
              name: "是",
            },
            {
              id: false,
              name: "否",
            },
          ],
          label: "name",
          value: "id"
        }
      },
      // {
      //   type: "Select",
      //   key: "otaUpgradeStatus",
      //   value: "",
      //   label: "OTA升级状态",
      //   options: {
      //     data: otaUpgradeList,
      //   }
      // },
      {
        type: "Select",
        key: "allowOtaUpgrade",
        value: "",
        label: "允许自动升级",
        options: {
          data: [
            { label: '是', value: true },
            { label: '否', value: false },
          ],
        }
      },
      {
        type: "Select",
        key: "otaClientVersion",
        value: "",
        label: "当前OTA版本",
        options: {
          data: otaVerList,
        }
      },
      // {
      //   type: "Date",
      //   key: "otaUpgradeTimeRange",
      //   value: [],
      //   label: "升级操作时间",
      //   options: {
      //     "type": "datetimerange",
      //     "format": "YYYY-MM-DD HH:mm:ss",
      //     "value-format": "YYYY-MM-DD HH:mm:ss",
      //     "range-separator": "至",
      //     "start-placeholder": "开始日期",
      //     "end-placeholder": "结束日期",
      //     "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
      //   }
      // },
    ])

    const getData = () => {
      request("getRobotStatus", {
        ...state.query,
        page: state.page,
        size: state.size,
      })
        .then((data) => {
          state.tableDataList = data.records;
          state.total = data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const getOTAVerList = async () => {
      try {
        const res = await reqUpgradeVerList()
        otaVerList.value = res || []
      } catch (error) {
        console.error(error)
      }
    }
    const getOTAVer = async () => {
      try {
        const res = await reqUpgradeNewVer()
        state.otaNewVer = res
      } catch (error) {
        console.error(error)
      }
    }
    const handleClick = () => {
      getData();
    }
    const searchData = (data) => {
      state.query = data;
      getData();
    }
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    }
    const formatStatus = (row) => {
      return robotStatusMap[row.status];
    }
    const handleSelect = (data) => {
      state.batchDataList = data.map((item)=>{
        return item.robotId
      })
    }
    const upgradeOTA = async () => {
      try {
        await reqUpgradeBatch({
          robotIdList: state.batchDataList
        })
        getData();
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }

    getData()
    getOTAVer()
    getOTAVerList()

    return {
      ...toRefs(state),
      params,
      handleClick,
      searchData,
      updatePage,
      formatStatus,
      handleSelect,
      upgradeOTA
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>