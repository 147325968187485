<template>
  <div class="page-container">
    <Filter ref="filterRef" :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column prop="modelName" label="规格型号" />
      <el-table-column prop="cabinetId" label="控制器编码" />
      <el-table-column prop="errorCode" label="报警编码" />
      <el-table-column prop="errorDescription" label="报警详情" />
      <el-table-column prop="errorTime" label="发生时间" />
      <el-table-column prop="requestTime" label="申请下载时间" />
      <el-table-column label="故障详情" width="100">
        <template #default="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >最新故障下载</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="210" fixed="right">
        <template #default="{row}">
          <el-button v-if="row.errorRecordId && row.url" @click="downLoadFile(row.url)" type="text" size="small"
            >故障日志下载</el-button
          >
          <el-button v-else-if="row.errorRecordId" type="text" size="small"
            >等待机器人响应</el-button
          >
          <el-button v-else @click="pullRobotLog(row)" type="text" size="small"
            >拉取机器人日志</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import dayjs from 'dayjs'

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data() {
    return {
        params: [{
        key: 'robotId',
        value: '',
        label: '机器人编码'
      },{
        key: 'cabinetId',
        value: '',
        label: '控制柜编码'
      },{
        type: "Date",
        key: "dateRange",
        value: [dayjs().subtract(7, 'day').format('YYYY-MM-DD') +' 00:00:00', dayjs().format('YYYY-MM-DD') +' 23:59:59'],
        label: "发生时间",
        options: {
          "type": "datetimerange",
          "format": "YYYY-MM-DD HH:mm:ss",
          "value-format": "YYYY-MM-DD HH:mm:ss",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
        }
      }],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [
        // {
        //   robotId: "Zu7210101",
        //   productName: "JAKA Zu",
        //   robotModel: "Zu 7",
        //   cabinetId: "123456789",
        //   version: "15.0.0",
        //   companyName: "汽车零部件公司",
        //   alarmInfo: "4453——故障文字描述",
        //   eventTime: "2021-05-27 14:55:00.123",
        //   time: "2021-05-24 17:48:20",
        // },
      ],
    };
  },
  created() {
  },
  mounted() {
    this.$refs.filterRef?.searchData()
  },
  methods: {
    getData() {
      request("getErrorLog", {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then((data) => {
          this.tableDataList = data.records;
          this.total = data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDataMap() {
      try {
        request("getArea", {
          parentId: 1,
        }).then((data) => {
          this.provinceList = data;
        });
        request("getRobotStatusMap").then((data) => {
          this.robotStatusList = data;
        });
        request("getIndustryList").then((data) => {
          this.industryList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async pullRobotLog(item) {
      try {
        await request('pullRobotLog', {
          robotId: item.robotId,
          errorCode: item.errorCode,
          errorRecordId: item.id,
          errorTime: item.errorTime,
        })
        this.getData()
      } catch (error) {
        console.error(error)
      }
    },
    async handleClick(item) {
      try {
        this.downLoadFile(item.url)
      } catch (error) {
        console.error(error)
      }
    },

    downLoadFile(url) {
      let a = document.createElement('a');
      a.download = 'errorLog';
      a.href = url
      a.click();
    },
    searchData(data) {
      this.query = data
      this.getData()
    },
    updatePage(page, size) {
      this.page = page || 1
      this.size = size || 10
      this.getData()
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;  
  display: flex;
  flex-direction: column;
}
</style>