<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="robotId" label="机器人编码" />
      <el-table-column prop="jointOrder" label="关节序号" />
      <el-table-column prop="cabinetId" label="控制器编码" />
      <el-table-column prop="servoVersion" label="伺服版本" />
      <el-table-column prop="temperature" label="温度（℃）" />
      <el-table-column prop="voltage" label="电压（V）" />
      <el-table-column prop="currentFluctuation" label="电流波动（%）" />
      <el-table-column prop="averagePower" label="运行功率（W）" />
      <el-table-column prop="sumRunningCircles" label="关节总圈数" />
      <el-table-column prop="queryTime" label="查询时间" width="180" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button type="text" size="mini" @click="jointCharts(scope.row)">关节状态图</el-button>
          <el-button type="primary" plain size="mini" @click="handleClick(scope.row)"
            >刷新状态</el-button
          >
        </template>
      </el-table-column>
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "../../common/mixin";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
// import { useRouter } from 'vue-router'

export default {
  components: {
    Table,
    Filter
  },
  mixins: [common],
  data() {
    return {
      params: [{
        key: 'robotId',
        value: '',
        label: '机器人编码'
      },{
        key: 'cabinetId',
        value: '',
        label: '控制柜编码'
      }],
      query: {
        robotId: "",
        cabinetId: "",
        robotStatus: "",
        cabinetName: "",
        companyName: "",
        isCrash: "",
        isEmergency: "",
      },
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      request("getJointStatus", {
        ...this.query,
        page: this.page,
        size: this.size,
      })
        .then((data) => {
          this.tableDataList = data.records;
          this.total = data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDataMap() {
      try {
        request("getArea", {
          parentId: 1,
        }).then((data) => {
          this.provinceList = data;
        });
        request("getRobotStatusMap").then((data) => {
          this.robotStatusList = data;
        });
        request("getIndustryList").then((data) => {
          this.industryList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleClick() {
      this.getData();
    },
    searchData(data) {
      this.query = data
      this.getData()
    },
    updatePage(page, size) {
        this.page = page || 1
        this.size = size || 10
        this.getData()
    },
    jointCharts(row) {
      // const router = useRouter()
      this.$router.push({ path: '/status/jointChart', query: { robotId: row.robotId, jointId: row.jointOrder} })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
